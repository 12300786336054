import { useEffect, useState } from "react";

import { Breakpoint } from "../types/enum.types";
import { breakpoints } from "../utils/constants";

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint[]>([]);

  const calcInnerWidth = () => {
    const width = window.innerWidth;
    const arr = [];
    for (const key in breakpoints) {
      const value = breakpoints[key as keyof typeof breakpoints];
      if (width >= value) arr.push(key as Breakpoint);
    }
    setBreakpoint([...arr]);
  };

  useEffect(() => {
    calcInnerWidth();
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return breakpoint;
};
