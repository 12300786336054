interface Props {
  onClick?: () => void;
}
const BurgerCollapse: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="26"
      height="19"
      viewBox="0 0 26 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" width="18" height="3" rx="1.5" fill="#0B3550" />
      <rect y="8" width="26" height="3" rx="1.5" fill="#0B3550" />
      <rect x="6" y="16" width="14" height="3" rx="1.5" fill="#0B3550" />
    </svg>
  );
};

export default BurgerCollapse;
