const CloseIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="6.12766" fill="var(--color-primary)" />
      <path
        d="M24.4381 11.7441C23.9374 11.2433 23.1255 11.2433 22.6248 11.7441L18.092 16.2772L13.5591 11.744C13.0584 11.2432 12.2465 11.2432 11.7458 11.744C11.245 12.2448 11.245 13.0567 11.7458 13.5575L16.2787 18.0907L11.7447 22.625C11.244 23.1257 11.244 23.9377 11.7447 24.4384C12.2454 24.9392 13.0573 24.9392 13.558 24.4384L18.092 19.9042L22.6258 24.4383C23.1266 24.9391 23.9385 24.9391 24.4392 24.4383C24.9399 23.9376 24.9399 23.1256 24.4392 22.6249L19.9053 18.0907L24.4381 13.5576C24.9389 13.0568 24.9389 12.2449 24.4381 11.7441Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseIcon;
