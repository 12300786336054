import { EmploymentType } from "next-seo/lib/jsonld/jobPosting";

import { Sector } from "../types/company.type";
import { SalaryRateEnum } from "../types/enum.types";
import { ErrorState } from "../types/error.types";
import { OfferFilters } from "../types/offer.type";
import { JobTitle, Profile } from "../types/profile.types";
import { ContractType, ExperienceYears } from "../types/select.type";
import { months } from "./constants";
import { contractTypeMapping, ExperienceYearsMapping } from "./mapping";
export const isValidPassword = (password: string) => {
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(password);
};

export const checkOldPositionError = (
  hasNoExperience?: boolean,
  oldPosition?: JobTitle
) => {
  if (hasNoExperience || (oldPosition?.name && oldPosition?.level)) return true;
  return false;
};

export const passwordErrors = (password: string, confirmPassword: string) => {
  const errors: ErrorState[] = [
    {
      message: "Contient au minimum 8 caractères",
      validated: false,
    },
    {
      message: "Contient au moins un chiffre",
      validated: false,
    },
    {
      message: "Contient au moins une majuscule",
      validated: false,
    },
    {
      message: "Contient au moins une minuscule",
      validated: false,
    },
    {
      message: "Contient au moins un caractère spécial",
      validated: false,
    },
    {
      message: "Mots de passe identiques",
      validated: false,
    },
  ];
  if (password.length >= 8) errors[0].validated = true;
  if (/\d/.test(password)) errors[1].validated = true;
  if (/[A-Z]/.test(password)) errors[2].validated = true;
  if (/[a-z]/.test(password)) errors[3].validated = true;
  if (/[!@*#\$%\^\&]/.test(password)) errors[4].validated = true;
  if (password === confirmPassword && password.length > 0)
    errors[5].validated = true;
  return errors;
};

export const profileCompleteness = (profile: Profile & { email: string }) => {
  const nbRequiredFields = 11;
  let nbRequiredFilledFields = 0;

  if (profile.firstname) nbRequiredFilledFields++;
  if (profile.lastname) nbRequiredFilledFields++;
  if (profile.email) nbRequiredFilledFields++;
  if (profile.phoneNumber) nbRequiredFilledFields++;
  if (profile.degree) nbRequiredFilledFields++;
  if (checkOldPositionError(profile.hasNoExperience, profile.oldPosition))
    nbRequiredFilledFields++;
  if (profile.positions && profile.positions.length > 0)
    nbRequiredFilledFields++;
  if (profile.localizations && profile.localizations.length > 0)
    nbRequiredFilledFields++;
  if (profile.contractTypes && profile.contractTypes.length > 0)
    nbRequiredFilledFields++;
  if (profile.jobTimes && profile.jobTimes.length > 0) nbRequiredFilledFields++;
  if (profile.visible !== undefined) nbRequiredFilledFields++;
  const filledPercentage = Math.ceil(
    (nbRequiredFilledFields / nbRequiredFields) * 90
  );

  const nbOptionalFields = 6;
  let nbOptionalFilledFields = 0;
  if (profile.minSalary && profile.maxSalary) nbOptionalFilledFields++;
  if (profile.birthdate) nbOptionalFilledFields++;
  if (profile.linkedin) nbOptionalFilledFields++;
  if (profile.profileImage) nbOptionalFilledFields++;
  if (profile.languages && profile.languages.length > 0)
    nbOptionalFilledFields++;
  if (profile.cv) nbOptionalFilledFields++;
  const optionalPercentage = Math.ceil(
    (nbOptionalFilledFields / nbOptionalFields) * 10
  );
  return filledPercentage + optionalPercentage;
};

export const getDiff = (time1: Date, time2: Date) => {
  const diff = (time1.getTime() - time2.getTime()) / 1000;
  const diffHrs = diff / (60 * 60);
  const hours = Math.floor(diffHrs);
  const diffDays = diffHrs / 24;
  const days = Math.floor(diffDays);
  const diffWeeks = diffDays / 7;
  const weeks = Math.floor(diffWeeks);
  const diffMonths = diffDays / 30;
  const months = Math.floor(diffMonths);
  const diffYears = diffMonths / 12;
  const years = Math.floor(diffYears);
  if (years > 0) {
    return `${years} ${years > 1 ? "ans" : "an"}`;
  }
  if (months > 0) {
    return `${months} mois`;
  }
  if (weeks > 0) {
    return `${weeks} ${weeks > 1 ? "semaines" : "semaine"}`;
  }
  if (days > 0) {
    return `${days} ${days > 1 ? "jours" : "jour"}`;
  }
  return `${hours}h`;
};

export const formatPublishedDate = (publishedAt?: Date | number) => {
  return publishedAt
    ? `Publiée il y a ${getDiff(new Date(), new Date(publishedAt))}`
    : "";
};

export const formatOfferDate = (action: string, publishedAt: Date) => {
  return publishedAt
    ? action === "Brouillon"
      ? `${action} crée il y a ${getDiff(new Date(), new Date(publishedAt))} `
      : action === "En cours"
      ? "Publication en cours"
      : `${action} il y a ${getDiff(new Date(), new Date(publishedAt))}`
    : "";
};

export const formatArticleDate = (date?: Date) => {
  // return how many days ago the article was published
  if (!date) return "";
  return `Publié il y a ${getDiff(new Date(), new Date(date))}`;
};

const formatNumber = (number: number, salaryRate: SalaryRateEnum) =>
  salaryRate === SalaryRateEnum.YEARLY || salaryRate === SalaryRateEnum.MONTHLY
    ? number.toFixed(0)
    : number.toFixed(2);

export const formatOfferSalary = (
  minSalary: number,
  maxSalary: number,
  salaryRate: SalaryRateEnum
): string | undefined => {
  if (!minSalary && !maxSalary) return undefined;

  switch (salaryRate) {
    case "YEARLY":
      return maxSalary !== minSalary
        ? formatNumber(minSalary / 1000, SalaryRateEnum.YEARLY) +
            "K€ - " +
            formatNumber(maxSalary / 1000, SalaryRateEnum.YEARLY) +
            "K€" +
            " / an"
        : formatNumber(maxSalary / 1000, SalaryRateEnum.YEARLY) +
            "K€" +
            " / an";

    case "MONTHLY":
      return maxSalary !== minSalary
        ? formatNumber(minSalary, SalaryRateEnum.MONTHLY) +
            "€ - " +
            formatNumber(maxSalary, SalaryRateEnum.MONTHLY) +
            "€" +
            " / mois"
        : formatNumber(maxSalary, SalaryRateEnum.MONTHLY) + "€" + " / mois";

    case "DAILY":
      return maxSalary != minSalary
        ? formatNumber(minSalary, SalaryRateEnum.DAILY) +
            "€ - " +
            formatNumber(maxSalary, SalaryRateEnum.DAILY) +
            "€" +
            " / jour"
        : formatNumber(maxSalary, SalaryRateEnum.DAILY) + "€" + " / jour";

    case "HOURLY":
      return maxSalary != minSalary
        ? formatNumber(minSalary, SalaryRateEnum.HOURLY) +
            "€ - " +
            formatNumber(maxSalary, SalaryRateEnum.HOURLY) +
            "€" +
            " / heure"
        : formatNumber(maxSalary, SalaryRateEnum.HOURLY) + "€" + " / heure";
  }
};

export const formatContractTypes = (contractTypes?: ContractType[]) => {
  return contractTypes?.map((c) => contractTypeMapping[c]).join(" - ");
};

export const getOfferDate = (offerDate?: Date) => {
  if (offerDate) {
    const date = new Date(offerDate);
    const year = date?.getFullYear();
    const month = date?.getMonth();
    const day = date?.getDate();
    return `${day}${day === 1 ? "er" : ""} ${months[month]} ${year} `;
  }
  return "";
};

export const formatOfferExperience = (experience?: ExperienceYears) => {
  if (!experience) return "";
  if (experience === "NO_EXPERIENCE") return ExperienceYearsMapping[experience];
  return ExperienceYearsMapping[experience] + " d’expérience";
};

export const formatSectors = (sectors?: Sector[]) => {
  if (!sectors) return "";
  let result = "";
  if (sectors.length >= 2) result += sectors[0].name + " - " + sectors[1].name;
  else if (sectors.length == 1) result += sectors[0].name;
  return result;
};

export const getDifferenceBetweenDates = (date1: Date, date2: Date) => {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(diff / (1000 * 60 * 60 * 24));
};

export const formatDate = (createdAt: Date) => {
  return `Le ${createdAt.toLocaleDateString("fr")}`;
};

export const deviceType = () => {
  const deviceWidth = window.innerWidth;
  if (deviceWidth > 768) {
    return "Desktop";
  }
  if (deviceWidth < 768 && deviceWidth > 425) {
    return "Tablet";
  }
  if (deviceWidth < 425) {
    return "Mobile";
  }
};

// convert date to dd/mm/yyyy format frenc
export const formatFullDate = (date: Date) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
};

export const mapContractTypeToEmploymentType = (
  contractType: ContractType
): EmploymentType => {
  switch (contractType) {
    case "CDI":
      return "FULL_TIME";
    case "CDD":
      return "TEMPORARY";
    case "STAGE":
      return "INTERN";
    case "INTERIM":
      return "TEMPORARY";
    case "ALTERNANCE":
      return "TEMPORARY";
    case "INDEPENDANT":
      return "CONTRACTOR";
    case "FREELANCE":
      return "CONTRACTOR";
  }
  return "OTHER";
};

export const formatSalary = (minSalary?: number, maxSalary?: number) =>
  minSalary && maxSalary
    ? `${minSalary}€ - ${maxSalary}€`
    : minSalary
    ? `${minSalary}€`
    : maxSalary
    ? `${maxSalary}€`
    : undefined;

const mapContractType = (contractType?: ContractType) => {
  switch (contractType) {
    case "CDI":
      return " CDI";
    case "CDD":
      return " CDD";
    case "STAGE":
      return " Stage";
    case "INTERIM":
      return " Interim";
    case "ALTERNANCE":
      return " Alternance";
    case "INDEPENDANT":
      return " Contrats de Prestation";
    case "FREELANCE":
      return " Contrats de Prestation";
    case "SAISONNIER":
      return " Saisonnier";
    case "TRAINING":
      return " Apprentissage";
    case "VOLUNTEER":
      return " Bénévolat";
  }
  return "";
};

const map2ContractType = (contractType?: ContractType) => {
  switch (contractType) {
    case "CDI":
      return "d'un CDI";
    case "CDD":
      return "d'un CDD";
    case "STAGE":
      return "d'un stage";
    case "INTERIM":
      return "d'un contrat d'interim";
    case "ALTERNANCE":
      return "d'une alternance";
    case "INDEPENDANT":
      return "d'un contrat de prestation";
    case "FREELANCE":
      return "d'un contrat de prestation";
  }
  return "";
};

export const generateOffersTitle = (filters: OfferFilters, page?: number) => {
  const { contractTypes, title, cities } = filters;
  if (!title) return;
  const mappedTitle = `${title} `;
  const mappedCities = cities && cities.length > 0 ? `à ${cities[0]} ` : "";
  const mappedContractType = mapContractType(contractTypes?.[0]);
  const mappedPage = page && page > 1 ? ` Page ${page}` : "";
  return `Offres d'emploi ${mappedTitle}${mappedCities}${mappedContractType}${mappedPage}`;
};

export const generateOffersMetaTitle = (
  filters: OfferFilters,
  page?: number
) => {
  const baseTitle = generateOffersTitle(filters);
  const displayDefaultTitle = !filters.title;
  const mappedPage = page && page > 1 ? ` | Page ${page}` : "";
  const metaTitle = displayDefaultTitle
    ? "Offres d'Emploi Médicales, Santé et Pharmacie"
    : baseTitle;
  return page ? `${metaTitle} | Swala${mappedPage}` : `${metaTitle} | Swala`;
};

export const generateOffersMetaDescription = (
  filters: OfferFilters,
  page?: number
) => {
  const { contractTypes, title, cities } = filters;
  const displayDefaultDescription =
    !title && !contractTypes?.length && !cities?.length;
  const mappedContractType = map2ContractType(contractTypes?.[0]);
  const mappedTitle = title ? ` : ${title}` : "";
  const mappedCities = cities && cities.length > 0 ? ` à ${cities[0]}` : "";
  const mappedPage = page ? `Page ${page}` : "";

  const metaDescription = displayDefaultDescription
    ? "Vous êtes à la recherche d'un emploi dans le médical ? Découvrez toutes nos offres d'emploi sur Swala, la plateforme 100% santé plus lisible qu'une ordonnance."
    : `Vous êtes à la recherche ${mappedContractType}${mappedTitle}${mappedCities} ? Découvrez toutes nos offres d'emploi ${title} sur Swala, la plateforme 100% santé plus lisible qu'une ordonnance.`;
  return page ? `${metaDescription} | ${mappedPage}` : metaDescription;
};
