import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import styles from "./NavLink.module.scss";

interface Props {
  label: string;
  path?: string;
  secondPath?: string;
  isPopupMenu?: boolean;
}

const NavLink: React.FC<Props> = ({ label, path, secondPath, isPopupMenu }) => {
  const router = useRouter();
  const currentRoute = router.asPath;

  return (
    <>
      {!isPopupMenu && (
        <Link href={path ? path : currentRoute} legacyBehavior>
          <a
            className={
              currentRoute === path || currentRoute === secondPath
                ? styles.active
                : styles.in_active
            }
          >
            {label}
          </a>
        </Link>
      )}
      {isPopupMenu && (
        <a
          className={
            currentRoute === path || currentRoute === secondPath
              ? styles.active
              : styles.in_active
          }
        >
          {label}
        </a>
      )}
    </>
  );
};

export default NavLink;
