export const HOME_PAGE = "/";
export const APPLICANT_HOME_PAGE = "/accueil/";
export const LOGIN_PAGE = "/connexion/";
export const INSCRIPTION_PAGE = "/inscription/";
export const CANDIDATE_INSCRIPTION_PAGE = "/inscription/candidat/";
export const RECRUITER_INSCRIPTION_PAGE = "/inscription/entreprise/";

export const OFFER_PAGE = "/offre/";
export const OFFERS_PAGE = "/offres/";
export const ENTREPRISE_SLUG_OFFERS_PAGE = "/offres-entreprise/";
export const OFFERS_METIER_PAGE = "/offres/metier/";

export const ARTICLES_PAGE = "/articles/";
export const PROFILE_PAGE = "/profil/";
export const CANDIDATURES_PAGE = "/candidatures/";
export const NOTIFICATIONS_PAGE = "/notifications/";
export const FAVORITES_PAGE = "/favoris/";
export const ENTREPRISE_PAGE = "/entreprise/";
export const ENTREPRISES_PAGE = "/entreprises/";
export const ENTREPRISE_OFFERS_PAGE = "/entreprise/offres/";
export const ENTREPRISE_PROFILE_PAGE = "/entreprise/profil/";
export const ENTREPRISE_SETTING_PAGE = "/entreprise/mon-compte/";
export const CVLIBRARY_PAGE = "/cvtheque/";
export const POURQUOI_SWALA_PAGE = "/pourquoi-swala/";
export const POURQUOI_SWALA_CANDIDATE = "/pourquoi-swala/candidat/";
export const POURQUOI_SWALA_RECRUITER = "/pourquoi-swala/entreprise/";
export const KANBAN_PAGE = "/kanban/";
export const VERIFY_EMAIL_PAGE = "/verification-email/";
export const NOT_FOUND_PAGE = "/404/";
export const GONE_PAGE = "/410/";
export const FORGET_PASSWORD_PAGE = "/mot-de-passe-oublie/";
export const RESET_PASSWORD_PAGE = "/reinitialisation-mot-de-passe/";
export const LEGAL_NOTICE = "/mention-legales/";
export const DATA_PRIVACY = "/confidentialite-des-donnees/";
export const FAQ_PAGE = "/faq/";
export const SAVED_SEARCH_PAGE = "/recherches-enregistrees/";

export const FACEBOOK_URL = "https://www.facebook.com/";
export const LINKEDIN_URL = "https://www.linkedin.com/";
export const TWITTER_URL = "https://twitter.com/";
export const INSTAGRAM_URL = "https://www.instagram.com/";
export const DEMO_URL =
  "https://cta-eu1.hubspot.com/web-interactives/public/v1/track/redirect?encryptedPayload=AVxigLINJjQI4YS05X755LN0JBCpArXd4sGmuFROI3uSBAWihSOnuaeAxX87%2B4L3xtQs347IJo0%2FJFAnll0Dm0v9y%2BPUw2h1Zdf9TnUWD9PlSuv3xSeB3yIVBOQxoBYTTSyVhRreGjz73qQbyW1o3pUF4GQuOQlzNLmz1dQa7qWR1tTP%2BLS17PcT3l16go3tACNG2m5DuW1aTGsGlqvsEw%3D%3D&webInteractiveContentId=80161495279&portalId=139821865";
