import { useRouter } from "next/router";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import styles from "./Icons.module.scss";

interface Props {
  white?: boolean;
  entreprise?: boolean;
  footer?: boolean;
}

const Logo: React.FC<Props> = ({ white, entreprise = false }) => {
  const router = useRouter();
  return (
    <LazyLoadImage
      effect="blur"
      width="150px"
      height={white ? "auto" : "auto"}
      onClick={() => {
        router.push("/");
      }}
      className={styles.pointer}
      src={`${
        entreprise
          ? "/assets/images/entreprise.png"
          : white
          ? "/assets/images/footer.png"
          : "/assets/images/profile.png"
      }`}
      alt="Swala, recrutement santé"
    />
  );
};

export default Logo;
